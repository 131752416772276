import React, { useEffect, useState } from 'react'

import 'antd/lib/avatar/style/css'
import 'antd/lib/button/style/css'
import 'antd/lib/dropdown/style/css'
import 'antd/lib/icon/style/css'
import 'antd/lib/menu/style/css'
import { useDispatch, useSelector } from 'react-redux'
import Avatar from 'antd/lib/avatar'
import Button from 'antd/lib/button'
import Dropdown from 'antd/lib/dropdown'
import Icon from 'antd/lib/icon'
import Menu from 'antd/lib/menu'
import styled from 'styled-components'

import { InlineLogin } from './LoginForms'
import {
  nineDotsEnabled,
  redesignEnabled,
  toggleSwitchEnabled
} from '../../utils/featureFlags'
import auth from '../../ducks/auth'
import Loader from '../Loader/Loader'
import { NineDots, ToggleSwitch } from 'frontend-components'
import nineDotsCards from '../../services/nineDotsConfig'

const {
  actions: { logout },
  selectors: {
    authorizeLoading: authorizeLoadingSelector,
    isLoggedIn: isLoggedInSelector,
    username: usernameSelector
  }
} = auth

const StyledLoader = styled(Loader)`
  height: 36px;
  width: 36px;
`

const StyledOverlay = styled.div`
  background-color: #e8e8e8;
  height: 96px;
  opacity: 0.8;
  position: absolute;
  right: -20px;
  width: calc(100% - 178px);
`

const StyledStatus = styled.div`
  align-items: center;
  display: flex;
  height: 100%;

  &.expanded {
    justify-content: flex-end;
    position: absolute;
    right: 0;

    width: 100vw;
    min-width: 1050px;
  }

  .ant-avatar-icon i {
    position: relative;
    top: -4px;
  }
`

const UserDetails = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: 10px;
  }
  & > span {
    margin-right: 5px;
  }
`

const menu = props => (
  <Menu>
    <Menu.Item data-test-name='menu-logout-item' onClick={props.handleLogout}>
      Logout
    </Menu.Item>
  </Menu>
)

const ToggleRedesign = () => {
  const [checked, setChecked] = useState(redesignEnabled())
  if (checked === true) {
    localStorage.setItem('redesignEnabled', true)
    setTimeout(() => window.location.replace('app'), 100)
  }
  return (
    <ToggleSwitch checked={checked} handleToggle={() => setChecked(!checked)} />
  )
}

const CollapsedHeader = ({ setCollapsed }) => (
  <StyledStatus>
    {toggleSwitchEnabled() && <ToggleRedesign />}
    {nineDotsEnabled() && <NineDots cards={nineDotsCards} name='Plotter' />}
    <Button
      data-test-name='collapsed-header-button'
      type='default'
      onClick={() => setCollapsed(false)}
    >
      Log in
    </Button>
  </StyledStatus>
)

const LoggedInHeader = () => {
  const dispatch = useDispatch()
  const handleLogout = () => dispatch(logout())
  const username = useSelector(state => usernameSelector(state))

  return (
    <StyledStatus>
      {toggleSwitchEnabled() && <ToggleRedesign />}
      {nineDotsEnabled() && <NineDots cards={nineDotsCards} name='Plotter' />}
      <div data-test-name='logged-in-header-dropdown-trigger'>
        <Dropdown
          overlay={menu({
            handleLogout: handleLogout
          })}
          placement='bottomLeft'
        >
          <UserDetails>
            <Avatar shape='circle' size='default' icon='user' />
            <span data-test-name='login-header-username'>{username}</span>
            <Icon type='down' />
          </UserDetails>
        </Dropdown>
      </div>
    </StyledStatus>
  )
}

const LoggedOutHeader = ({ setCollapsed }) => {
  return (
    <StyledStatus className='expanded'>
      <InlineLogin collapseLogin={() => setCollapsed(true)} />
      <StyledOverlay />
    </StyledStatus>
  )
}

const LoginStatus = () => {
  const [collapsed, setCollapsed] = useState(true)

  const authorizeLoading = useSelector(state => authorizeLoadingSelector(state))
  const isLoggedIn = useSelector(state => isLoggedInSelector(state))

  useEffect(() => {
    if (isLoggedIn && collapsed) {
      setCollapsed(false)
    }
  }, [isLoggedIn, collapsed])

  if (authorizeLoading) {
    return <StyledLoader />
  } else if (collapsed) {
    return <CollapsedHeader setCollapsed={setCollapsed} />
  } else if (isLoggedIn) {
    return <LoggedInHeader />
  }

  return <LoggedOutHeader setCollapsed={setCollapsed} />
}

export default LoginStatus
