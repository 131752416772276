import { useDispatch, useSelector } from 'react-redux'
import { getTokenAndUser } from '../services/authStorage'
import auth from '../ducks/auth'

const {
  actions: { authorize },
  selectors: {
    authorizeLoading: authorizeLoadingSelector,
    error: errorSelector,
    isLoggedIn: isLoggedInSelector
  }
} = auth

export default function useAuthorization () {
  const dispatch = useDispatch()

  const { token, username } = getTokenAndUser()
  const error = useSelector(state => errorSelector(state))
  const isAuthorizing = useSelector(state => authorizeLoadingSelector(state))
  const isLoggedIn = useSelector(state => isLoggedInSelector(state))

  const shouldAuthorize =
    !!(token && username) && !error && !isAuthorizing && !isLoggedIn

  if (shouldAuthorize) {
    dispatch(authorize({ token, username }))
  }

  return {
    error,
    isAuthorizing,
    isLoggedIn,
    shouldAuthorize
  }
}
