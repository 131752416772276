import React, { useState, useRef, useEffect } from 'react'

import '../../assets/styles/map.css'
import 'antd/dist/antd.css'
import { bindTheme } from 'frontend-components'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import Collapse from 'antd/lib/collapse'
import Drawer from 'antd/lib/drawer'
import GoogleMapReact from 'google-map-react'
import Popover from 'antd/lib/popover'
import Spin from 'antd/lib/spin'
import styled, { createGlobalStyle, useTheme } from '@xstyled/styled-components'

import { ChartStreamDataProvider } from '../ChartStream/ChartStream.redesign'
import { customMapStyles, theme } from './theme'
import { redesignEnabled } from '../../utils/featureFlags'
import { usePmus } from '../../hooks'
import { UseSuperCluster } from '../../services/superCluster'
import auth from '../../ducks/auth'
import ChartStream from '../ChartStream/ChartStream'
import lighting from '../../assets/images/icons/lighting.svg'
import MetadataPreview from '../StreamSearch/MetadataPreview'
import plotter from '../../ducks/plotter'

const ChartStreamComponent = redesignEnabled()
  ? ChartStreamDataProvider
  : ChartStream

const colors = bindTheme(theme)

const GlobalTheme = createGlobalStyle`
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    background-color: ${colors(
      'styledPopover.antPopoverInner.backgroundColor'
    )};

    .ant-popover-inner-content {
      background-color: ${colors(
        'styledPopover.antPopoverInner.backgroundColor'
      )};

      pre {
        background-color: ${colors(
          'styledPopover.antPopoverInnerContent.pre.backgroundColor'
        )};
        color: ${colors('styledPopover.antPopoverInnerContent.pre.color')};
      }
    }

    .ant-popover-title {
      color: ${colors('styledDrawer.antDrawerBody.color')};
    }
  }
`

const StyledChart = styled.div`
  .reset-button {
    display: none !important;
  }
`

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    background-color: ${colors('styledDrawer.antDrawerBody.backgroundColor')};

    .ant-collapse {
      > .ant-collapse-item {
        border-bottom: 1px solid
          ${colors('styledDrawer.antDrawerBody.borderColor')};
      }

      &.ant-collapse-icon-position-left {
        background-color: transparent;
        border: 1px solid ${colors('styledDrawer.antDrawerBody.borderColor')};
        color: ${colors('styledDrawer.antDrawerBody.color')};
      }

      .ant-collapse-header {
        color: ${colors('styledDrawer.antDrawerBody.color')};

        svg {
          fill: ${colors('styledDrawer.antDrawerBody.svg.fill')};
        }
      }
    }
  }

  .ant-drawer-content {
    background-color: transparent;

    .ant-collapse-content-box {
      background-color: ${colors('styledDrawer.antDrawerBody.backgroundColor')};
    }
  }

  .ant-drawer-header {
    background-color: ${colors('styledDrawer.antDrawerHeader.backgroundColor')};

    .ant-drawer-title {
      color: ${colors('styledDrawer.antDrawerHeader.color')};
    }

    svg {
      fill: ${colors('styledDrawer.antDrawerHeader.svg.fill')};
    }
  }
`

const StyledMap = styled.div`
  height: calc(100vh - 96px);
  position: relative;
  width: 100%;
`
const StyledMarker = styled.div`
  width: 25px;
  cursor: pointer;
`
const StyledLoading = styled.div`
  position: relative;
  top: -200px;
  left: 700px;
`
const StyledLoadingText = styled.div`
  font-size: 16px;
  position: relative;
  right: -130px;
  top: -30px;
`
const StyledLodingBackground = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.9);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  text-align: center;
  opacity: 0.8;

  @media (max-height: 1120px) {
    height: 150%;
  }
`

const getDefaultZoom = () => {
  if (window.location.host === 'plot.ni4ai.org') {
    return 2
  } else {
    return 10
  }
}

const getInitialCoords = () => {
  if (window.location.host === 'plot.ni4ai.org') {
    return { lat: 37.0, lng: -120.0 }
  } else {
    return { lat: 37.82156667, lng: -77.44360278 }
  }
}

const useCache = false
const Marker = ({ children }) => children
const Loading = () => {
  return (
    <StyledLodingBackground>
      <StyledLoading data-style-name='styled-loading'>
        <Spin size='large' />
        <StyledLoadingText>Currently loading pmu devices</StyledLoadingText>
      </StyledLoading>
    </StyledLodingBackground>
  )
}

const NoPmuFound = () => {
  return (
    <StyledLodingBackground>
      <StyledLoading data-style-name='styled-loading'>
        <StyledLoadingText>No pmu devices available</StyledLoadingText>
      </StyledLoading>
    </StyledLodingBackground>
  )
}

const LoggedOut = () => {
  return (
    <StyledLodingBackground>
      <StyledLoading data-style-name='styled-loading'>
        <StyledLoadingText>Please login to see pmu devices</StyledLoadingText>
      </StyledLoading>
    </StyledLodingBackground>
  )
}

const ErrorLoadingPmu = () => {
  return (
    <StyledLodingBackground>
      <StyledLoading data-style-name='styled-loading'>
        <StyledLoadingText>Failed loading pmu devices</StyledLoadingText>
      </StyledLoading>
    </StyledLodingBackground>
  )
}

const chartDimensions = {
  width: 500,
  height: 300,
  margin: {
    top: 40,
    bottom: 20,
    left: 0,
    right: 30
  }
}

const { Panel } = Collapse

const CollapseContainer = ({ onRowPlusMinus, selected, streamsList }) => {
  const theme = useTheme()

  return (
    <Collapse>
      {streamsList &&
        streamsList.map(stream => {
          return (
            <Panel
              header={stream.annotations.description || stream.collection}
              className={stream.uuid}
              key={stream.uuid}
            >
              <Tooltip
                placement='right'
                title='Select stream to view in visualization page'
              >
                {typeof selected[0][stream.uuid] === 'undefined' && (
                  <FontAwesomeIcon
                    style={{
                      width: '25px',
                      height: '25px',
                      marginLeft: '480px',
                      color: colors('icon.plusCircle')({ theme })
                    }}
                    icon={faPlusCircle}
                    onClick={() => {
                      onRowPlusMinus({ metadata: stream })
                    }}
                  />
                )}
                {typeof selected[0][stream.uuid] !== 'undefined' && (
                  <FontAwesomeIcon
                    style={{
                      width: '25px',
                      height: '25px',
                      marginLeft: '480px',
                      color: colors('icon.timesCircle')({ theme })
                    }}
                    icon='times-circle'
                    onClick={() => {
                      onRowPlusMinus({ metadata: stream })
                    }}
                  />
                )}
              </Tooltip>
              <Popover
                placement='rightTop'
                content={<MetadataPreview metadata={stream} />}
                title={stream.tags.name}
              >
                <StyledChart>
                  <ChartStreamComponent
                    axes={[{ unit: '', side: 'left', streams: [stream.uuid] }]}
                    dimensions={{
                      ...chartDimensions
                    }}
                    // initialEndTime={globalDefaultZoom[1]}
                    // initialStartTime={globalDefaultZoom[0]}
                    key={stream.uuid}
                    plots={[
                      {
                        uuid: stream.uuid,
                        unit: '',
                        color: colors('icon.plusCircle')({ theme }),
                        visibility: true
                      }
                    ]}
                    showLegend={false}
                    uuid={stream.uuid}
                  />
                </StyledChart>
              </Popover>
            </Panel>
          )
        })}
    </Collapse>
  )
}

const StreamsListContainer = ({ onClose, visible, streamsList }) => {
  const dispatch = useDispatch()

  const selectedRows = useSelector(plotter.selectors.selectedRows)

  const onRowPlusMinus = ({ metadata }) => {
    const { uuid } = metadata
    dispatch(plotter.actions.toggleStream({ metadata, uuid }))
  }

  return (
    <StyledDrawer
      className='ant-drawer'
      closable
      key='left'
      keyboard
      maskClosable
      onClose={onClose}
      placement='left'
      title='Streams list'
      visible={visible}
      width={600}
    >
      <GlobalTheme />
      <CollapseContainer
        onRowPlusMinus={onRowPlusMinus}
        selected={[selectedRows]}
        streamsList={streamsList}
      />
    </StyledDrawer>
  )
}

const {
  selectors: { isLoggedIn: isLoggedInSelector }
} = auth

const MapContainer = () => {
  const theme = useTheme()
  const isLoggedIn = useSelector(state => isLoggedInSelector(state))
  const { data, error, loading, success } = usePmus({ useCache })
  const mapRef = useRef()
  const [bounds, setBounds] = useState(null)
  const [zoom, setZoom] = useState(10)
  const [visible, setvisible] = useState(false)
  const [streamGeoLocation, setStreamGeoLocation] = useState(null)
  const [streamsList, setStreamsList] = useState(null)
  const defaultCenter = getInitialCoords()
  const defaultZoom = getDefaultZoom()
  const googleApiKey = {
    key: !!window.__env__ && window.__env__.REACT_APP_GOOGLE_KEY
  }
  const streams = data && !error && !loading ? data.slice(0, 15000) : []
  const points = streams.map(stream => ({
    type: 'Feature',
    properties: {
      cluster: false,
      streamId: stream.uuid,
      category: stream.collection
    },
    geometry: {
      type: 'Point',
      coordinates: [
        parseFloat(stream.annotations.longitude),
        parseFloat(stream.annotations.latitude)
      ]
    }
  }))
  const { clusters, supercluster } = UseSuperCluster(points, bounds, zoom)

  const zoomBound = ({ zoom, bounds }) => {
    setZoom(zoom)
    setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat])
  }

  const mapOptions = map => {
    return {
      fullscreenControl: false,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: map?.MapTypeControlStyle.HORIZONTAL_BAR,
        position: map?.ControlPosition.TOP_RIGHT
      },
      ...(redesignEnabled() && { styles: customMapStyles })
    }
  }

  const mapLoaded = ({ map }) => {
    mapRef.current = map
  }

  const showDrawer = () => {
    setvisible(true)
  }

  const onClose = () => {
    setvisible(false)
    setStreamsList(null)
  }

  useEffect(() => {
    function onShowStreamsList () {
      const list = []
      if (streamGeoLocation) {
        streams.forEach(stream => {
          if (
            JSON.stringify([
              parseFloat(stream.annotations.latitude),
              parseFloat(stream.annotations.longitude)
            ]) ===
            JSON.stringify([
              streamGeoLocation.latitude,
              streamGeoLocation.longitude
            ])
          ) {
            list.push(stream)
          }
        })
      }

      setStreamsList(list)
    }

    if (streamGeoLocation) {
      onShowStreamsList()
      showDrawer()
    }
    // eslint-disable-next-line
  }, [streamGeoLocation])

  return (
    <StyledMap className='map'>
      <GoogleMapReact
        bootstrapURLKeys={googleApiKey}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={mapLoaded}
        onChange={zoomBound}
        options={mapOptions}
      >
        <StreamsListContainer
          onClose={onClose}
          visible={visible}
          streamsList={streamsList}
        />
        {error && <ErrorLoadingPmu />}
        {!isLoggedIn && !loading && !success && !error && <LoggedOut />}
        {data.length === 0 && !success && !loading && !error && <NoPmuFound />}
        {isLoggedIn && loading && !error && !success && <Loading />}
        {isLoggedIn &&
          clusters.length > 0 &&
          clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates
            const {
              cluster: isCluster,
              point_count: pointCount
            } = cluster.properties
            if (isCluster) {
              const zoomCluster = () => {
                const expansionZoom = Math.min(
                  supercluster.getClusterExpansionZoom(cluster.id),
                  20
                )
                mapRef.current.setZoom(expansionZoom)
                mapRef.current.panTo({ lat: latitude, lng: longitude })
              }

              const style = {
                background: colors('clusterMarker.background')({ theme }),
                height: `${10 + (pointCount / points.length) * 20}px`,
                width: `${10 + (pointCount / points.length) * 20}px`
              }

              return (
                <Marker
                  key={`cluster-${cluster.id}`}
                  lat={latitude}
                  lng={longitude}
                >
                  <div
                    className='cluster-marker'
                    style={style}
                    onClick={zoomCluster}
                  >
                    {pointCount}
                  </div>
                </Marker>
              )
            } else {
              return (
                <Marker
                  key={`station-${cluster.properties.streamId}`}
                  lat={latitude}
                  lng={longitude}
                >
                  <StyledMarker>
                    <img
                      src={lighting}
                      alt='stream'
                      onClick={() => {
                        setStreamGeoLocation({ latitude, longitude })
                      }}
                    />
                  </StyledMarker>
                </Marker>
              )
            }
          })}
      </GoogleMapReact>
    </StyledMap>
  )
}

export default MapContainer
